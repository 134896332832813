import { useEffect, Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@twilio-paste/core";
import axios from "axios";

import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import {
    addNotification,
    finishConversation as closeConversation,
    removeNotification
} from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const { conversation, conversationState } = useSelector((state: AppState) => state.chat);
    const [isClosing, setIsClosing] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    const handleEndConversation = useCallback(() => {
        if (!conversation?.sid) return null;

        setIsClosing(true);
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/webchat/close`,
            method: "post",
            data: {
                conversationSid: conversation.sid
            }
        })
            .then(() => {
                // Finish chat
                dispatch(closeConversation());
                setIsClosed(true);
            })
            .catch(() => {
                dispatch(addNotification(notifications.failedToCloseConversation()));
            })
            .finally(() => {
                setIsClosing(false);
            });

        return 1;
    }, [conversation?.sid, dispatch]);

    return (
        <Wrapper>
            <Header>
                <Box as="div">
                    {conversationState === "active" && (
                        <Button
                            hidden={isClosed}
                            variant="primary"
                            type="button"
                            onClick={handleEndConversation}
                            disabled={isClosing}
                        >
                            Finalizar chat
                        </Button>
                    )}
                </Box>
            </Header>
            <NotificationBar />
            <MessageList />
            {conversationState === "active" ? <MessageInput /> : <ConversationEnded />}
        </Wrapper>
    );
};
