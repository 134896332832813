import { BoxStyleProps } from "@twilio-paste/core/box";

export const outerContainerStyles: BoxStyleProps = {
    position: "fixed",
    bottom: "space50",
    right: "space60",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-end"
};

export const buttonContainerStyles: BoxStyleProps = {
    flexDirection: "column",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginTop: "auto"
};

export const innerContainerStyles: BoxStyleProps = {
    boxShadow: "shadow",
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "500px",
    marginBottom: "space50",
    borderRadius: "borderRadius30",
    backgroundColor: "colorBackgroundBody"
};
