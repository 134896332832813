/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { FormEvent, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { HelpText, Input, Option, Select } from "@twilio-paste/core";
import { Text } from "@twilio-paste/core/text";
import { AxiosError } from "axios";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import { buttonContainerStyles } from "./styles/RootContainer.styles";
import { analytics } from '../utils/segment';

const requestTypes = [
    {
        value: "grades",
        label: "Tengo dudas sobre mi materia actual"
    },
    {
        value: "subject",
        label: "Tengo dudas sobre mi calificación"
    },
    {
        value: "career",
        label: "Tengo dudas generales sobre mi carrera"
    }
];

export const PreEngagementFormPhase = () => {
    const { name, email, requestType } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const [error, setError] = useState<string | undefined>();
    const dispatch = useDispatch();

    const handleSubmit = async (e: FormEvent) => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        setError(undefined);
        e.preventDefault();


        try {

            // handle errors
            if (!email?.endsWith("@ukuepa.com")) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                return setError("Debes utilizar un correo académico");
            }

            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    email,
                    requestType
                }
            });

            return dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            return dispatch(
                addNotification({
                    id: `FailedToInitSessionNotification`,
                    dismissible: true,
                    message:
                        (err as AxiosError).response?.data?.message ?? (err as Error).message ?? "Ha ocurrido un error",
                    type: "error"
                })
            );
        } finally {
            // handle track Segment's event
            try {
                analytics.track({
                    userId: email,
                    event: 'user-init-webchat',
                    properties: {
                        email,
                        friendlyName: name,
                        requestType
                    }
                } as any);
            } catch (error) {
                console.log('error at tracking...')
                console.log(error)
            }
        }
    };

    return (
        <>
            {/* <Header customTitle="Tutor Virtual" /> */}
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Bienvenido a la Uk
                </Text>
                <Text {...introStyles} as="p">
                    Necesitamos que por favor nos brindes algunos datos para personalizar tu experiencia al máximo
                </Text>

                <Box as="div" {...fieldStyles}>
                    <Label htmlFor="name">Nombre completo</Label>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Ej. Juan Perez"
                        onChange={(e) => {
                            dispatch(updatePreEngagementData({ name: e.target.value }));
                        }}
                        required
                    />
                </Box>

                <Box as="div" {...fieldStyles}>
                    <Label htmlFor="email">Email</Label>
                    <Input
                        id="email_address"
                        name="email_address"
                        type="email"
                        placeholder="juan.perez@ukuepa.com"
                        onChange={(e) => {
                            dispatch(updatePreEngagementData({ email: e.target.value }));
                        }}
                        required
                    />
                    {error ? <HelpText id="email_help_text">{error}</HelpText> : null}
                </Box>

                <Box as="div" {...fieldStyles}>
                    <Label htmlFor="requestType">Tipo de la consulta</Label>
                    <Select
                        id="requestType"
                        defaultValue={requestType}
                        onChange={(e) => {
                            dispatch(updatePreEngagementData({ requestType: e.target.value }));
                        }}
                    >
                        {requestTypes.map((i, index) => {
                            return (
                                <Option key={index} value={i.value}>
                                    {i.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Box>

                <Box as="div" {...buttonContainerStyles}>
                    <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                        Comenzar
                    </Button>
                </Box>
            </Box>
        </>
    );
};
