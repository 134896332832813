import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

import { containerStyles, titleStyles } from "./styles/Header.styles";
import { buttonContainerStyles } from "./styles/RootContainer.styles";

export const Header = ({ customTitle, children }: { customTitle?: string; children?: React.ReactNode }) => {
    return (
        <Box as="header" {...containerStyles} style={{ paddingRight: "10px" }}>
            <Text as="h2" {...titleStyles}>
                {customTitle || "Tutor Virtual"}
            </Text>

            {children ?? null}
        </Box>
    );
};
